
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');
    window.Popper = require('popper.js').default;
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.moment = require('moment');
moment.locale('es');
console.log('moment config', moment.locale());

require('jquery-confirm');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

require('./../../public/js/sidebarmenu.js');
// require('./../../public/assets/plugins/sidebar-nav/src/metisMenu.js');
require('./../../public/js/waves.js');
require('./../../public/js/jquery.slimscroll.js');
require('./../../public/assets/plugins/sticky-kit-master/dist/sticky-kit.min.js');
require('./../../public/assets/plugins/sparkline/jquery.sparkline.min.js');
require('./../../public/js/ui-custom.js');

window.Toast = require('./../../public/assets/plugins/toast-master/js/jquery.toast.js');
window.Dialog = require('./../../public/assets/plugins/dialog/jquery.dialog.js');


var $el = $('.fixedElement'); 
var isPositionFixed = ($el.css('position') == 'fixed');
if (!isPositionFixed){ 
    $el.css({'position': 'fixed', 'width': '100%'}); 
}
if (isPositionFixed){
    $el.css({'position': 'static'}); 
} 


$(window).scroll(function(e){ 
    var isPositionFixed = ($el.css('position') == 'fixed');
        if ($(this).scrollTop() > 67 && !isPositionFixed){ 
            $(".container-fluid").css({'padding-top': 40+$(".page-titles").height()+'px'});
            $(".btn-menu").slideDown();
            $el.css({'position': 'fixed', 'width': $(".page-wrapper").width()+5, 'margin-left': '0px', 'padding-top': ''}); 
        }        
        if ($(this).scrollTop() < 67 && isPositionFixed){
            $(".btn-menu").slideUp();
            $(".container-fluid").css({'padding-top':'0px'});
            $el.css({'position': 'static', 'width': '', 'margin-left': '', 'padding-top': ''}); 
        } 
});

/* $(".sidebartoggler").click(function() {
    $el.css({'position': 'fixed', 'width': "96%", 'margin-left': '-10px', 'padding-top': ''}); 
}); */

        
var getContrast = function (hexcolor){
	if (hexcolor.slice(0, 1) === '#') {
		hexcolor = hexcolor.slice(1);
	}	
	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(function (hex) {
			return hex + hex;
		}).join('');
	}
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);	
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
	return (yiq >= 128) ? ( $(".img-fluid").removeClass("convertLogo") )  : ( $(".img-fluid").addClass("convertLogo") );

};

var fullColorHex = function(arrayRGB) {   
  var red = rgbToHex(arrayRGB[0]);
  var green = rgbToHex(arrayRGB[1]);
  var blue = rgbToHex(arrayRGB[2]);
  return red+green+blue;
};

var rgbToHex = function (rgb) { 
  var hex = Number(rgb).toString(16);
  if (hex.length < 2) {
       hex = "0" + hex;
  }
  return hex;
};


try {
    
} catch (error) {
    getContrast("#" + fullColorHex(  $(".topbar").css('background-color').replace('rgb(', '').replace(')','' ).split(',').map(Number) ))    
}